/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { GenericObject, WebError } from '@dpa/ui-common';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { get } from 'lodash-es';

import {
  ActionData,
  Automation,
  AutomationAction,
  AutomationCondition,
  AutomationDialogMode,
  AutomationSchedule,
  AutomationSearchRequest,
  AutomationSearchResponse,
  AutomationType,
  AutomationWizardMode,
  ConnectionTestMode,
  ConnectorAction,
  MetaForm,
  RuleGroup,
  SearchTerm,
  SourceObjectType,
  WorkflowConditionBranchKey,
  WorkflowTaskType,
  WorkflowTaskUtil,
} from '@ws1c/intelligence-models';
import { AutomationCommonActions } from './automation-common.actions';
import { AutomationCommonState, initialAutomationCommonState } from './automation-common.store';

const _reducer: ActionReducer<AutomationCommonState, Action> = createReducer(
  initialAutomationCommonState,
  on(
    AutomationCommonActions.loadAutomation,
    (state: AutomationCommonState, { automation }: ReturnType<typeof AutomationCommonActions.loadAutomation>): AutomationCommonState => ({
      ...state,
      automation: new Automation(automation),
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationSuccess,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.loadAutomationSuccess>,
    ): AutomationCommonState => ({
      ...state,
      automation: new Automation({
        ...state.automation,
        ...automation,
      }),
    }),
  ),
  on(
    AutomationCommonActions.setAutomation,
    (state: AutomationCommonState, { automation }: ReturnType<typeof AutomationCommonActions.setAutomation>): AutomationCommonState => ({
      ...state,
      automation,
    }),
  ),
  on(
    AutomationCommonActions.getAutomationQuotasSuccess,
    (
      state: AutomationCommonState,
      { automationQuotas }: ReturnType<typeof AutomationCommonActions.getAutomationQuotasSuccess>,
    ): AutomationCommonState => ({
      ...state,
      automationQuotas,
    }),
  ),
  on(
    AutomationCommonActions.getAutomationQuotasFailure,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationQuotas: [],
    }),
  ),
  on(
    AutomationCommonActions.automateFromDashboard,
    AutomationCommonActions.automateFromStandardDashboard,
    AutomationCommonActions.automateFromWidgetDetails,
    AutomationCommonActions.automateFromReportList,
    AutomationCommonActions.automateFromReportDetails,
    AutomationCommonActions.automateFromDataExplorer,
    (state: AutomationCommonState, automationStateParams: GenericObject): AutomationCommonState => ({
      ...state,
      automationWizardMode: AutomationWizardMode.CREATE_FROM_OTHER_SOURCE,
      automationStateParams,
    }),
  ),
  on(
    AutomationCommonActions.automateFromSourceObject,
    (
      state: AutomationCommonState,
      { sourceObjectType }: ReturnType<typeof AutomationCommonActions.automateFromSourceObject>,
    ): AutomationCommonState => {
      let automationWizardMode = AutomationWizardMode.CREATE_FROM_OTHER_SOURCE;
      if (sourceObjectType === SourceObjectType.INCIDENT) {
        automationWizardMode = AutomationWizardMode.CREATE;
      }
      return {
        ...state,
        automationWizardMode,
      };
    },
  ),
  on(AutomationCommonActions.requestCreateAutomation, (state: AutomationCommonState): AutomationCommonState => {
    const wizardMode =
      state.automationWizardMode === AutomationWizardMode.CREATE_FROM_OTHER_SOURCE
        ? state.automationWizardMode
        : AutomationWizardMode.CREATE;

    const automationWizardModel =
      state.automationWizardMode === AutomationWizardMode.CREATE_FROM_OTHER_SOURCE
        ? new Automation(state.automationWizardModel)
        : new Automation();

    return {
      ...state,
      automationWizardMode: wizardMode,
      automationWizardModel,
      detailsAutomation: null,
    };
  }),
  on(AutomationCommonActions.requestEditAutomation, (state: AutomationCommonState): AutomationCommonState => {
    let wizardMode = AutomationWizardMode.EDIT;
    if (
      state.automationWizardMode === AutomationWizardMode.EDIT_FROM_AUTOMATION_DETAILS ||
      state.automationWizardMode === AutomationWizardMode.EDIT_FROM_OTHER_SOURCE
    ) {
      wizardMode = state.automationWizardMode;
    }
    return {
      ...state,
      automationWizardMode: wizardMode,
      detailsAutomation: null,
    };
  }),
  on(
    AutomationCommonActions.editFromOtherSource,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationWizardMode: AutomationWizardMode.EDIT_FROM_OTHER_SOURCE,
    }),
  ),
  on(
    AutomationCommonActions.clearAutomationWizard,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.CLOSE,
      automationPageUrl: initialAutomationCommonState.automationPageUrl,
      automationWizardMode: AutomationWizardMode.CLOSE,
      automationWizardModel: undefined,
      cancelWizardReturnPath: undefined,
      previewData: undefined,
      previewDataLastRefreshedAt: undefined,
      testConnectionStatus: ConnectionTestMode.IDLE,
      sourceObject: undefined,
      isSubPage: false,
      conditionNameIndex: 0,
      groupNameIndex: 0,
      activeAutomationType: AutomationType.GENERAL,
    }),
  ),
  on(
    AutomationCommonActions.clearAutomationStateParams,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationStateParams: null,
    }),
  ),
  on(
    AutomationCommonActions.setSourceObject,
    (
      state: AutomationCommonState,
      { sourceObjectId, sourceObjectType }: ReturnType<typeof AutomationCommonActions.setSourceObject>,
    ): AutomationCommonState => ({
      ...state,
      isSubPage: true,
      sourceObject: {
        sourceObjectId,
        sourceObjectType,
      },
    }),
  ),
  on(
    AutomationCommonActions.clearSourceObject,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      sourceObject: undefined,
      isSubPage: false,
    }),
  ),
  on(
    AutomationCommonActions.requestCopyAutomation,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.requestCopyAutomation>,
    ): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.COPY,
      automationDialogModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.requestRenameAutomation,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.requestRenameAutomation>,
    ): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.RENAME,
      automationDialogModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.requestDeleteAutomation,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.requestDeleteAutomation>,
    ): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.DELETE,
      automationDialogModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.requestPreviewAutomation,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.PREVIEW,
      automationDialogModel: null,
    }),
  ),
  on(
    AutomationCommonActions.closeAutomationDialog,
    AutomationCommonActions.goToEditAutomationPage,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.CLOSE,
      automationDialogModel: null,
      testConnectionResult: null,
    }),
  ),
  on(
    AutomationCommonActions.requestChangeAutomationStatus,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.requestChangeAutomationStatus>,
    ): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.TOGGLE,
      automationDialogModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.requestRunAutomation,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.requestRunAutomation>,
    ): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.RUN,
      automationDialogModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.confirmTemplateUpdate,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.UPDATE,
    }),
  ),
  on(
    AutomationCommonActions.setAutomationPageUrl,
    (state: AutomationCommonState, { url }: ReturnType<typeof AutomationCommonActions.setAutomationPageUrl>): AutomationCommonState => ({
      ...state,
      automationPageUrl: url,
    }),
  ),
  on(
    AutomationCommonActions.resetWizardUrls,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationPageUrl: initialAutomationCommonState.automationPageUrl,
      cancelWizardReturnPath: initialAutomationCommonState.cancelWizardReturnPath,
    }),
  ),
  on(
    AutomationCommonActions.setCancelReturnPath,
    (
      state: AutomationCommonState,
      { cancelWizardReturnPath }: ReturnType<typeof AutomationCommonActions.setCancelReturnPath>,
    ): AutomationCommonState => ({
      ...state,
      cancelWizardReturnPath,
    }),
  ),
  on(
    AutomationCommonActions.setSuccessReturnPath,
    (
      state: AutomationCommonState,
      { successWizardReturnPath }: ReturnType<typeof AutomationCommonActions.setSuccessReturnPath>,
    ): AutomationCommonState => ({
      ...state,
      successWizardReturnPath,
    }),
  ),
  on(
    AutomationCommonActions.setAutomationDetails,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.setAutomationDetails>,
    ): AutomationCommonState => ({
      ...state,
      detailsAutomation: automation,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationList,
    (state: AutomationCommonState, { request }: ReturnType<typeof AutomationCommonActions.loadAutomationList>): AutomationCommonState => ({
      ...state,
      loading: true,
      request,
      fromDetailView: false,
      response: null,
      detailsAutomation: null,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationPreviewResults,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loading: true,
      response: new AutomationSearchResponse(),
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationListSuccess,
    (
      state: AutomationCommonState,
      { response }: ReturnType<typeof AutomationCommonActions.loadAutomationListSuccess>,
    ): AutomationCommonState => ({
      ...state,
      loading: false,
      response,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationListFailure,
    (
      state: AutomationCommonState,
      { error }: ReturnType<typeof AutomationCommonActions.loadAutomationListFailure>,
    ): AutomationCommonState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    AutomationCommonActions.filterAutomations,
    (state: AutomationCommonState, { text }: ReturnType<typeof AutomationCommonActions.filterAutomations>): AutomationCommonState => {
      return {
        ...state,
        loading: true,
        request: new AutomationSearchRequest({
          ...state.request,
          searchTerm: new SearchTerm({
            value: text,
          }),
          from: 0,
        }),
      };
    },
  ),
  on(
    AutomationCommonActions.loadMoreAutomations,
    (
      state: AutomationCommonState,
      { automationSearchRequest }: ReturnType<typeof AutomationCommonActions.loadMoreAutomations>,
    ): AutomationCommonState => ({
      ...state,
      loadingMore: true,
      request: automationSearchRequest,
    }),
  ),
  on(
    AutomationCommonActions.loadMoreAutomationsSuccess,
    (
      state: AutomationCommonState,
      { response }: ReturnType<typeof AutomationCommonActions.loadMoreAutomationsSuccess>,
    ): AutomationCommonState => ({
      ...state,
      loadingMore: false,
      response: new AutomationSearchResponse({
        from: response.from,
        size: response.size,
        results: state.response.results.concat(response.results),
        total: response.total,
      }),
    }),
  ),
  on(
    AutomationCommonActions.automationSaveAndEnableConfirm,
    (
      state: AutomationCommonState,
      { fromDetailView }: ReturnType<typeof AutomationCommonActions.automationSaveAndEnableConfirm>,
    ): AutomationCommonState => ({
      ...state,
      fromDetailView,
    }),
  ),
  on(
    AutomationCommonActions.clearAutomationPreviewData,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      previewData: undefined,
    }),
  ),
  on(
    AutomationCommonActions.setAutomationPreviewData,
    (
      state: AutomationCommonState,
      { previewData }: ReturnType<typeof AutomationCommonActions.setAutomationPreviewData>,
    ): AutomationCommonState => ({
      ...state,
      previewData,
    }),
  ),
  on(
    AutomationCommonActions.showSaveAndEnableDialog,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    AutomationCommonActions.showEditEnabledAutomationModal,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.showEditEnabledAutomationModal>,
    ): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.EDIT_CONFIRMATION,
      automationDialogModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.showSaveAndEnableDialogSuccess,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loading: false,
      automationDialogMode: AutomationDialogMode.SAVE_AND_ENABLE,
    }),
  ),
  on(
    AutomationCommonActions.showSaveDraftDialog,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationDialogMode: AutomationDialogMode.SAVE_DRAFT,
    }),
  ),
  on(
    AutomationCommonActions.setTestAttributes,
    (
      state: AutomationCommonState,
      { testAttributes }: ReturnType<typeof AutomationCommonActions.setTestAttributes>,
    ): AutomationCommonState => ({
      ...state,
      testAttributes,
    }),
  ),
  on(
    AutomationCommonActions.loadTestDynamicValuesTable,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      testDynamicValuesLoading: true,
    }),
  ),
  on(
    AutomationCommonActions.loadTestDynamicValuesSuccess,
    (
      state: AutomationCommonState,
      { testDynamicValues }: ReturnType<typeof AutomationCommonActions.loadTestDynamicValuesSuccess>,
    ): AutomationCommonState => ({
      ...state,
      testDynamicValues,
      testDynamicValuesLoading: false,
    }),
  ),
  on(
    AutomationCommonActions.loadTestDynamicValuesFailure,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      testDynamicValues: undefined,
      testDynamicValuesLoading: false,
    }),
  ),
  on(
    AutomationCommonActions.confirmConnectorActionTest,
    (
      state: AutomationCommonState,
      { automationAction, connector, connectorAction }: ReturnType<typeof AutomationCommonActions.confirmConnectorActionTest>,
    ): AutomationCommonState => {
      const testableAutomationAction: AutomationAction = automationAction;
      const actionMethod = state.connectorsActionsResponse?.data.find((availableActionMethod: ConnectorAction) => {
        return connectorAction.key === availableActionMethod.key && connectorAction.connectorId === availableActionMethod.connectorId;
      });
      const testableMetaForm = actionMethod?.sectionsMetaForm ?? new MetaForm();
      return {
        ...state,
        automationDialogMode: AutomationDialogMode.TEST,
        testConnectionStatus: ConnectionTestMode.IDLE,
        testableAutomationAction,
        testableConnectorAction: connectorAction,
        testableConnector: connector,
        testableMetaForm,
      };
    },
  ),
  on(
    AutomationCommonActions.loadAllActions,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loadingActions: true,
    }),
  ),
  on(
    AutomationCommonActions.loadAllActionsSuccess,
    (
      state: AutomationCommonState,
      { connectorsActionsResponse }: ReturnType<typeof AutomationCommonActions.loadAllActionsSuccess>,
    ): AutomationCommonState => ({
      ...state,
      connectorsActionsResponse,
      loadingActions: false,
    }),
  ),
  on(
    AutomationCommonActions.loadAllActionsFailure,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loadingActions: false,
    }),
  ),
  on(
    AutomationCommonActions.testConnectorAction,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      testConnectionStatus: ConnectionTestMode.TESTING,
    }),
  ),
  on(
    AutomationCommonActions.testConnectorActionSuccess,
    (
      state: AutomationCommonState,
      { actionTestResult }: ReturnType<typeof AutomationCommonActions.testConnectorActionSuccess>,
    ): AutomationCommonState => ({
      ...state,
      testConnectionStatus: ConnectionTestMode.SUCCESS,
      testConnectionResult: actionTestResult,
    }),
  ),
  on(
    AutomationCommonActions.testConnectorActionFailure,
    (
      state: AutomationCommonState,
      { actionTestResult }: ReturnType<typeof AutomationCommonActions.testConnectorActionFailure>,
    ): AutomationCommonState => ({
      ...state,
      testConnectionStatus: ConnectionTestMode.FAILURE,
      testConnectionResult: actionTestResult,
    }),
  ),
  on(
    AutomationCommonActions.selectAutomationRepeatTrigger,
    (
      state: AutomationCommonState,
      { repeatTrigger }: ReturnType<typeof AutomationCommonActions.selectAutomationRepeatTrigger>,
    ): AutomationCommonState => ({
      ...state,
      automationWizardModel: new Automation({
        ...state.automationWizardModel,
        repeatTrigger,
      }),
    }),
  ),
  on(
    AutomationCommonActions.selectAutomationEvaluationType,
    (
      state: AutomationCommonState,
      { evaluationType }: ReturnType<typeof AutomationCommonActions.selectAutomationEvaluationType>,
    ): AutomationCommonState => ({
      ...state,
      automationWizardModel: new Automation({
        ...state.automationWizardModel,
        evaluationType,
        repeatTrigger: false,
      }),
    }),
  ),
  on(
    AutomationCommonActions.setCronExpressionData,
    (
      state: AutomationCommonState,
      { cronExpressionDetail }: ReturnType<typeof AutomationCommonActions.setCronExpressionData>,
    ): AutomationCommonState => {
      const schedule: AutomationSchedule = AutomationSchedule.createFromAutomationAndScheduleDetails(
        { cronExpressionDetail },
        get(state.automationWizardModel, 'schedule.id'),
      );
      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
          schedule,
        }),
      };
    },
  ),
  on(AutomationCommonActions.clearCronExpressionData, (state: AutomationCommonState): AutomationCommonState => {
    return {
      ...state,
      automationWizardModel: new Automation({
        ...state.automationWizardModel,
        schedule: undefined,
      }),
    };
  }),
  on(
    AutomationCommonActions.updateWizardModel,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.updateWizardModel>,
    ): AutomationCommonState => ({
      ...state,
      automationWizardModel: getAutomationWithUiIds({
        ...state.automationWizardModel,
        ...automation,
      }),
    }),
  ),
  on(
    AutomationCommonActions.addTriggerToWizardModel,
    (
      state: AutomationCommonState,
      { queryBuilder }: ReturnType<typeof AutomationCommonActions.addTriggerToWizardModel>,
    ): AutomationCommonState => {
      const filter = queryBuilder?.getQueryString() ?? '';
      const wizardCondition = (state.automationWizardModel && state.automationWizardModel.condition) || new AutomationCondition();
      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
          condition: new AutomationCondition({
            ...wizardCondition,
            conditionType: 'SIMPLE_FILTER',
            // If the queryBuilder is undefined reset the filter, because wizardCondition.filter holds the previous value
            filter,
            ruleGroup: new RuleGroup({
              rules: queryBuilder?.group?.rules ?? [],
              operator: queryBuilder?.group?.operator,
            }),
          }),
        }),
      };
    },
  ),
  on(
    AutomationCommonActions.loadAutomationTemplateDetailsSuccess,
    (
      state: AutomationCommonState,
      { automation, updatePreviewModelOnly }: ReturnType<typeof AutomationCommonActions.loadAutomationTemplateDetailsSuccess>,
    ): AutomationCommonState => {
      const automationModel = getAutomationWithUiIds({
        ...automation,
        actions: (automation.actions ?? []).map((action: AutomationAction) => {
          return AutomationAction.create(action);
        }),
      });

      if (updatePreviewModelOnly) {
        return {
          ...state,
          automationPreviewModel: automationModel,
        };
      }
      return {
        ...state,
        automationWizardModel: automationModel,
      };
    },
  ),
  on(
    AutomationCommonActions.applyPreviewModel,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationWizardModel: new Automation({
        ...state.automationPreviewModel,
      }),
      automationPreviewModel: undefined,
    }),
  ),
  on(
    AutomationCommonActions.resetPreviewModel,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      automationPreviewModel: undefined,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationsBySourceObjectId,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      detailsAutomation: undefined,
      loading: true,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationsBySourceObjectIdSuccess,
    (
      state: AutomationCommonState,
      { request, response }: ReturnType<typeof AutomationCommonActions.loadAutomationsBySourceObjectIdSuccess>,
    ): AutomationCommonState => ({
      ...state,
      request,
      response,
      loading: false,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationsBySourceObjectIdFailure,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    AutomationCommonActions.refreshAutomations,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      request: new AutomationSearchRequest(state.request),
    }),
  ),
  on(
    AutomationCommonActions.sortAutomations,
    (state: AutomationCommonState, { sortOns }: ReturnType<typeof AutomationCommonActions.sortAutomations>): AutomationCommonState => ({
      ...state,
      request: new AutomationSearchRequest(state.request, {
        sortOns,
        from: 0,
      }),
    }),
  ),
  on(
    AutomationCommonActions.changePagination,
    (
      state: AutomationCommonState,
      { pagedRequest }: ReturnType<typeof AutomationCommonActions.changePagination>,
    ): AutomationCommonState => ({
      ...state,
      request: new AutomationSearchRequest(state.request, {
        ...pagedRequest,
      }),
    }),
  ),
  on(
    AutomationCommonActions.confirmCreateAutomation,
    AutomationCommonActions.confirmEditAutomation,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    AutomationCommonActions.confirmCreateAutomationSuccess,
    AutomationCommonActions.confirmCreateAutomationFailure,
    AutomationCommonActions.updateAutomationSuccess,
    AutomationCommonActions.updateAutomationFailure,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    AutomationCommonActions.setAutomationWizardModel,
    (
      state: AutomationCommonState,
      { automation }: ReturnType<typeof AutomationCommonActions.setAutomationWizardModel>,
    ): AutomationCommonState => ({
      ...state,
      automationWizardModel: automation,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationEditor,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      previewData: undefined,
      previewDataLastRefreshedAt: undefined,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationPreviewTable,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      previewDataLoading: true,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationPreviewDataSuccess,
    (
      state: AutomationCommonState,
      { response }: ReturnType<typeof AutomationCommonActions.loadAutomationPreviewDataSuccess>,
    ): AutomationCommonState => ({
      ...state,
      previewData: response,
      previewDataLastRefreshedAt: Date.now(),
      previewDataLoading: false,
    }),
  ),
  on(
    AutomationCommonActions.loadAutomationPreviewDataFailure,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      previewDataLoading: false,
    }),
  ),
  on(
    AutomationCommonActions.addWorkflowTask,
    (
      state: AutomationCommonState,
      {
        workflowTaskType,
        addToWorkflowTask,
        addToWorkflowConditionBranch,
        insertAtIndex,
      }: ReturnType<typeof AutomationCommonActions.addWorkflowTask>,
    ): AutomationCommonState => {
      let nameIndex: number;
      if (workflowTaskType === WorkflowTaskType.CONDITION) {
        nameIndex = state.conditionNameIndex + 1;
      }
      if (workflowTaskType === WorkflowTaskType.GROUP) {
        nameIndex = state.groupNameIndex + 1;
      }
      const task: AutomationAction = WorkflowTaskUtil.create(
        state.automationWizardModel,
        workflowTaskType,
        undefined,
        undefined,
        undefined,
        nameIndex,
      );

      WorkflowTaskUtil.addNewTask(state.automationWizardModel, task, insertAtIndex, addToWorkflowTask, addToWorkflowConditionBranch);
      WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, task, true);

      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
        }),
        conditionNameIndex: workflowTaskType === WorkflowTaskType.CONDITION ? nameIndex : state.conditionNameIndex,
        groupNameIndex: workflowTaskType === WorkflowTaskType.GROUP ? nameIndex : state.groupNameIndex,
      };
    },
  ),
  on(
    AutomationCommonActions.addActionsToWorkflowTask,
    (
      state: AutomationCommonState,
      { workflowTask, payload }: ReturnType<typeof AutomationCommonActions.addActionsToWorkflowTask>,
    ): AutomationCommonState => {
      if (workflowTask?.isAction) {
        workflowTask.actionData = new ActionData(workflowTask.actionData, payload[0]);
        WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, workflowTask);
      } else if (workflowTask?.isGroup) {
        const newTasks = (payload ?? []).map((actionMethod: any) => {
          return WorkflowTaskUtil.create(state.automationWizardModel, WorkflowTaskType.ACTION, workflowTask, undefined, actionMethod);
        });
        workflowTask.actionData.groupedActions = [...(workflowTask.actionData.groupedActions ?? []), ...newTasks];
        WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, workflowTask);
      }

      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
        }),
      };
    },
  ),
  on(
    AutomationCommonActions.updateActionOfWorkflowTask,
    (
      state: AutomationCommonState,
      { workflowTask, payload }: ReturnType<typeof AutomationCommonActions.updateActionOfWorkflowTask>,
    ): AutomationCommonState => {
      workflowTask.actionData = new ActionData(workflowTask.actionData, payload);
      WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, workflowTask);
      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
        }),
      };
    },
  ),

  on(
    AutomationCommonActions.deleteWorkflowTask,
    (
      state: AutomationCommonState,
      { taskToDelete }: ReturnType<typeof AutomationCommonActions.deleteWorkflowTask>,
    ): AutomationCommonState => {
      WorkflowTaskUtil.deleteWorkflowTask(state.automationWizardModel, taskToDelete);
      WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, taskToDelete, true);
      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
        }),
      };
    },
  ),

  on(
    AutomationCommonActions.updateWorkflowConditionTaskFilter,
    (
      state: AutomationCommonState,
      { workflowTask, queryBuilder }: ReturnType<typeof AutomationCommonActions.updateWorkflowConditionTaskFilter>,
    ): AutomationCommonState => {
      workflowTask.actionData.expression = new AutomationCondition({
        filter: queryBuilder?.getQueryString() ?? '',
        ruleGroup: new RuleGroup({
          rules: queryBuilder?.group?.rules ?? [],
          operator: queryBuilder?.group?.operator,
        }),
      });

      WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, workflowTask);

      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
        }),
      };
    },
  ),

  on(
    AutomationCommonActions.reArrangeWorkflowTasks,
    (
      state: AutomationCommonState,
      {
        parentWorkflowTask,
        parentWorkflowConditionBranch,
        previousIndex,
        currentIndex,
      }: ReturnType<typeof AutomationCommonActions.reArrangeWorkflowTasks>,
    ): AutomationCommonState => {
      const areRootLevelTasks = !parentWorkflowTask || !parentWorkflowConditionBranch;
      let tasks = [];

      if (areRootLevelTasks) {
        tasks = state.automationWizardModel.actions; // add at root level at the end
      } else {
        tasks = parentWorkflowTask.actionData.decisionCases[WorkflowConditionBranchKey[parentWorkflowConditionBranch]];
      }

      const newTasks = [...tasks];
      moveItemInArray(newTasks, previousIndex, currentIndex);

      if (areRootLevelTasks) {
        state.automationWizardModel.actions = newTasks;
      } else {
        parentWorkflowTask.actionData.decisionCases = {
          ...parentWorkflowTask.actionData.decisionCases,
          [WorkflowConditionBranchKey[parentWorkflowConditionBranch]]: newTasks,
        };
        WorkflowTaskUtil.updateRefUpTheTree(state.automationWizardModel, parentWorkflowTask);
      }
      return {
        ...state,
        automationWizardModel: new Automation({
          ...state.automationWizardModel,
        }),
      };
    },
  ),
  on(
    AutomationCommonActions.requestFieldLookup,
    (state: AutomationCommonState): AutomationCommonState => ({
      ...state,
      lookupMapError: undefined,
      lookupMapLoading: true,
    }),
  ),
  on(
    AutomationCommonActions.loadLookupValues,
    (state: AutomationCommonState, { lookupMap }: ReturnType<typeof AutomationCommonActions.loadLookupValues>): AutomationCommonState => ({
      ...state,
      lookupMap: {
        ...state.lookupMap,
        ...lookupMap,
      },
      lookupMapError: undefined,
      lookupMapLoading: false,
    }),
  ),
  on(AutomationCommonActions.loadLookupValuesFailure, (state: AutomationCommonState, { error }): AutomationCommonState => {
    return {
      ...state,
      lookupMap: {},
      lookupMapError: error instanceof WebError ? error : undefined,
      lookupMapLoading: false,
    };
  }),

  on(
    AutomationCommonActions.setCurrentActionConnectorId,
    (
      state: AutomationCommonState,
      { connectorId }: ReturnType<typeof AutomationCommonActions.setCurrentActionConnectorId>,
    ): AutomationCommonState => ({
      ...state,
      currentActionConnectorId: connectorId,
    }),
  ),

  on(
    AutomationCommonActions.setActiveAutomationType,
    (
      state: AutomationCommonState,
      { automationType }: ReturnType<typeof AutomationCommonActions.setActiveAutomationType>,
    ): AutomationCommonState => ({
      ...state,
      activeAutomationType: automationType,
    }),
  ),
);

/**
 * Creates and returns AutomationCommonState
 * @export
 * @param {AutomationCommonState} state - Holds state object of type AutomationCommonState
 * @param {Action} action - Holds the action which needs to be invoked in reducer
 * @returns {AutomationCommonState}
 */
export function automationCommonState(state: AutomationCommonState, action: Action): AutomationCommonState {
  return _reducer(state, action);
}

/**
 * getAutomationWithUiIds
 * Attaches a uiId to automation.actions for *ngFor's trackBy
 * This prevents re-rendering dom elements which messes up animations
 * @param {Partial<Automation>} automation
 * @returns {Automation}
 */
function getAutomationWithUiIds(automation: Partial<Automation>): Automation {
  return new Automation({
    ...automation,
    actions: (automation.actions ?? []).map((action: AutomationAction) => {
      return action.uiId ? action : AutomationAction.create(action);
    }),
  });
}
