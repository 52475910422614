/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import {
  AggregationAttributesResponse,
  AggregationColumn,
  Category,
  Column,
  COLUMN_FILTERS,
  PrecomputedAggregation,
  SuggestionSearch,
  SuggestionSearchItem,
  Tag,
} from '@ws1c/intelligence-models';

/**
 * IntegrationMetaState
 * @export
 * @interface IntegrationMetaState
 */
export interface IntegrationMetaState {
  activeCategories: Category[];
  categories: Category[];
  columnsRequestCountByCategory: Record<string, number>;
  columnsByCategory: Map<Category, Column[]>;
  columnsByGroupSearchString: string;
  isCrossCategory: boolean;
  crossCategoryColumns: Record<string, Column[]>;
  filterValues: Record<string, boolean>;
  filterTags: Tag[];
  suggestionValuesBySuggestionSearch: Map<SuggestionSearch, SuggestionSearchItem[]>;
  suggestionLoadingBySuggestionSearch: Map<SuggestionSearch, boolean>;
  loadingPrecomputedAggregations: boolean;
  precomputedAggregationsByCategory: Record<string, PrecomputedAggregation[]>;
  aggregationColumnsByCategory: Record<string, AggregationColumn[]>;
  precomputedAggregationAttributeById: Record<string, AggregationAttributesResponse>;
  loadingCustomAttributeIdentifiers: boolean;
  customAttributeIdentifiersByAttributeName: Map<string, string[]>;
  customAttributeIdentifierAttributesByKey: Map<string, Column[]>;
}

export const initialIntegrationMetaState: IntegrationMetaState = {
  activeCategories: [],
  categories: [],
  crossCategoryColumns: {},
  isCrossCategory: false,
  columnsRequestCountByCategory: {},
  columnsByCategory: new Map<Category, Column[]>(),
  columnsByGroupSearchString: '',
  filterValues: {
    [COLUMN_FILTERS.NORMALIZED]: false,
  },
  filterTags: [],
  suggestionValuesBySuggestionSearch: new Map<SuggestionSearch, SuggestionSearchItem[]>(),
  suggestionLoadingBySuggestionSearch: new Map<SuggestionSearch, boolean>(),
  loadingPrecomputedAggregations: false,
  precomputedAggregationsByCategory: {},
  aggregationColumnsByCategory: {},
  precomputedAggregationAttributeById: {},
  loadingCustomAttributeIdentifiers: false,
  customAttributeIdentifiersByAttributeName: new Map<string, string[]>(),
  customAttributeIdentifierAttributesByKey: new Map<string, Column[]>(),
};
