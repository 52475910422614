/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep, each, get, keyBy } from 'lodash-es';

import {
  AggregationAttributesResponse,
  Category,
  CategoryColumns,
  Column,
  SuggestionSearch,
  SuggestionSearchItem,
  Tag,
} from '@ws1c/intelligence-models';
import { IntegrationMetaActions } from './integration-meta.actions';
import { initialIntegrationMetaState, IntegrationMetaState } from './integration-meta.state';

const _reducer = createReducer(
  initialIntegrationMetaState,

  on(
    IntegrationMetaActions.loadColumns,
    (
      state: IntegrationMetaState,
      { categoryId, isCrossCategory = false, setActiveCategory = true }: ReturnType<typeof IntegrationMetaActions.loadColumns>,
    ) => {
      const category = keyBy(state.categories, 'categoryId')[categoryId];
      return {
        ...state,
        activeCategories: setActiveCategory ? [category] : state.activeCategories,
        isCrossCategory,
      };
    },
  ),

  on(
    IntegrationMetaActions.loadColumnsSuccess,
    (state: IntegrationMetaState, { columns = [], category }: ReturnType<typeof IntegrationMetaActions.loadColumnsSuccess>) => {
      // Handle non join case first.
      // Once we add join for widgets, this if block can be removed.
      if (!state.isCrossCategory) {
        const columnsByCategory = new Map<Category, Column[]>(state.columnsByCategory);
        columnsByCategory.set(
          category,
          columns.map((column: Column) => {
            // INTEL-8669 API returns empty label if it doesn't find i18n value.
            return new Column({
              ...column,
              label: column.label || column.name,
            });
          }),
        );
        return {
          ...state,
          columnsByCategory,
        };
      }
      const categoriesById = keyBy(state.categories, 'categoryId');
      const columnsClone = columns.map(
        (column: Column) =>
          new Column({
            ...column,
            entityLabel: categoriesById[column.categoryId] && categoriesById[column.categoryId].label,
          }),
      );
      return {
        ...state,
        crossCategoryColumns: {
          ...state.crossCategoryColumns,
          [category.categoryId]: columnsClone,
        },
      };
    },
  ),

  on(
    IntegrationMetaActions.loadColumnsForMultiCategory,
    (state: IntegrationMetaState, props: { entitiesByIntegration: GenericObject; activeCategories: Category[] }) => {
      return {
        ...state,
        activeCategories: props.activeCategories,
        isCrossCategory: false,
      };
    },
  ),

  on(
    IntegrationMetaActions.loadColumnsForMultiCategorySuccess,
    (state: IntegrationMetaState, props: { categoryColumns: CategoryColumns }) => {
      const columnsByCategory = new Map<Category, Column[]>(state.columnsByCategory);
      const activeCategoriesById = keyBy(state.categories, (category: Category) => category.categoryId);
      each(props.categoryColumns.columnsByCategoryId, (columns: Column[], categoryId: string) => {
        columnsByCategory.set(activeCategoriesById[categoryId], columns);
      });
      return {
        ...state,
        columnsByCategory,
      };
    },
  ),

  on(
    IntegrationMetaActions.loadColumnsQuietly,
    (state: IntegrationMetaState, { categoryId }: ReturnType<typeof IntegrationMetaActions.loadColumnsQuietly>) => {
      return {
        ...state,
        columnsRequestCountByCategory: {
          ...state.columnsRequestCountByCategory,
          [categoryId]: get(state.columnsRequestCountByCategory, categoryId, 0) + 1,
        },
      };
    },
  ),

  on(IntegrationMetaActions.searchFilterValues, (state: IntegrationMetaState, props: { suggestionSearch: SuggestionSearch }) => {
    const suggestionLoadingBySuggestionSearch = new Map<SuggestionSearch, boolean>(state.suggestionLoadingBySuggestionSearch);
    suggestionLoadingBySuggestionSearch.set(props.suggestionSearch, true);
    return {
      ...state,
      suggestionLoadingBySuggestionSearch,
    };
  }),

  on(
    IntegrationMetaActions.searchFilterValuesSuccess,
    (
      state: IntegrationMetaState,
      props: {
        suggestionValues: SuggestionSearchItem[];
        suggestionSearch: SuggestionSearch;
      },
    ) => {
      const suggestionValuesBySuggestionSearch = new Map<SuggestionSearch, SuggestionSearchItem[]>(
        state.suggestionValuesBySuggestionSearch,
      );
      suggestionValuesBySuggestionSearch.set(props.suggestionSearch, props.suggestionValues);
      const suggestionLoadingBySuggestionSearch = new Map<SuggestionSearch, boolean>(state.suggestionLoadingBySuggestionSearch);
      suggestionLoadingBySuggestionSearch.set(props.suggestionSearch, false);
      return {
        ...state,
        suggestionValuesBySuggestionSearch,
        suggestionLoadingBySuggestionSearch,
      };
    },
  ),

  on(
    IntegrationMetaActions.searchFilterValuesFailure,
    (
      state: IntegrationMetaState,
      props: {
        suggestionSearch: SuggestionSearch;
      },
    ) => {
      const suggestionLoadingBySuggestionSearch = new Map<SuggestionSearch, boolean>(state.suggestionLoadingBySuggestionSearch);
      suggestionLoadingBySuggestionSearch.set(props.suggestionSearch, false);
      return {
        ...state,
        suggestionLoadingBySuggestionSearch,
      };
    },
  ),

  on(
    IntegrationMetaActions.getAvailableFilterTagsSuccess,
    (
      state: IntegrationMetaState,
      props: {
        filterTags: Tag[];
      },
    ) => {
      return {
        ...state,
        filterTags: props.filterTags,
      };
    },
  ),

  on(IntegrationMetaActions.cleanAvailableFilterTags, (state: IntegrationMetaState) => {
    return {
      ...state,
      filterTags: [],
    };
  }),

  on(
    IntegrationMetaActions.searchAvailableColumns,
    (
      state: IntegrationMetaState,
      props: {
        query: string;
        selected: string;
      },
    ) => {
      return {
        ...state,
        columnsByGroupSearchString: props.query === props.selected ? '' : props.query,
      };
    },
  ),

  on(
    IntegrationMetaActions.resetColumnsByGroupSearchString,
    (state: IntegrationMetaState): IntegrationMetaState => ({
      ...state,
      columnsByGroupSearchString: '',
    }),
  ),

  on(
    IntegrationMetaActions.toggleColumnFilter,
    (
      state: IntegrationMetaState,
      props: {
        name: string;
      },
    ): IntegrationMetaState => {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [props.name]: !state.filterValues[props.name],
        },
      };
    },
  ),

  on(IntegrationMetaActions.resetColumnFilters, (state: IntegrationMetaState): IntegrationMetaState => {
    return {
      ...state,
      filterValues: {
        ...initialIntegrationMetaState.filterValues,
      },
    };
  }),

  on(
    IntegrationMetaActions.loadCategoriesSuccess,
    (
      state: IntegrationMetaState,
      props: {
        categories: Category[];
      },
    ) => {
      return {
        ...state,
        categories: props.categories,
      };
    },
  ),

  on(
    IntegrationMetaActions.getPrecomputedAggregations,
    (
      state: IntegrationMetaState,
      { categoryId, setActiveCategory = true }: ReturnType<typeof IntegrationMetaActions.getPrecomputedAggregations>,
    ) => {
      const category = keyBy(state.categories, 'categoryId')[categoryId];
      const activeCategories = setActiveCategory ? [category] : state.activeCategories;
      return {
        ...state,
        loadingPrecomputedAggregations: true,
        activeCategories,
      };
    },
  ),

  on(
    IntegrationMetaActions.getPrecomputedAggregationsSuccess,
    (
      state: IntegrationMetaState,
      { categoryId, precomputedAggregations }: ReturnType<typeof IntegrationMetaActions.getPrecomputedAggregationsSuccess>,
    ) => {
      return {
        ...state,
        precomputedAggregationsByCategory: {
          ...state.precomputedAggregationsByCategory,
          [categoryId]: precomputedAggregations,
        },
        loadingPrecomputedAggregations: false,
      };
    },
  ),

  on(IntegrationMetaActions.getPrecomputedAggregationsFailure, (state: IntegrationMetaState) => {
    return {
      ...state,
      loadingPrecomputedAggregations: false,
    };
  }),

  on(
    IntegrationMetaActions.getAggregationAttributesSuccess,
    (
      state: IntegrationMetaState,
      { aggregationAttributesResponse, category }: ReturnType<typeof IntegrationMetaActions.getAggregationAttributesSuccess>,
    ) => {
      const activeCategory = category ?? state.activeCategories[0];
      const columnsByCategory = new Map<Category, Column[]>(state.columnsByCategory);
      columnsByCategory.set(activeCategory, aggregationAttributesResponse.attributeList);
      return {
        ...state,
        columnsByCategory,
        aggregationColumnsByCategory: {
          ...state.aggregationColumnsByCategory,
          [activeCategory.categoryId]: aggregationAttributesResponse.aggregationAttributesList,
        },
        // Just needed id, filter and filterConditionsNestedRules so creating a new object and setting only desired attributes
        precomputedAggregationAttributeById: {
          ...state.precomputedAggregationAttributeById,
          [aggregationAttributesResponse.id]: new AggregationAttributesResponse({
            id: aggregationAttributesResponse.id,
            filter: aggregationAttributesResponse.filter,
            filterConditionsNestedRules: aggregationAttributesResponse.filterConditionsNestedRules,
          }),
        },
      };
    },
  ),

  on(IntegrationMetaActions.clearAttributes, (state: IntegrationMetaState) => {
    const category = state.activeCategories[0];
    const columnsByCategory = new Map<Category, Column[]>(state.columnsByCategory);
    columnsByCategory.delete(category);
    const aggregationColumnsByCategory = cloneDeep(state.aggregationColumnsByCategory);
    aggregationColumnsByCategory[category.categoryId] = undefined;
    return {
      ...state,
      columnsByCategory,
      aggregationColumnsByCategory,
    };
  }),

  on(IntegrationMetaActions.clearActiveCategories, (state: IntegrationMetaState) => {
    return {
      ...state,
      activeCategories: [],
    };
  }),

  on(IntegrationMetaActions.loadCustomAttributeIdentifiers, (state: IntegrationMetaState) => {
    return {
      ...state,
      loadingCustomAttributeIdentifiers: true,
    };
  }),

  on(
    IntegrationMetaActions.loadCustomAttributeIdentifiersSuccess,
    (
      state: IntegrationMetaState,
      {
        customAttribute,
        customAttributeIdentifiersSearchResponse,
      }: ReturnType<typeof IntegrationMetaActions.loadCustomAttributeIdentifiersSuccess>,
    ) => {
      const customAttributeIdentifiersByAttributeName = new Map<string, string[]>(state.customAttributeIdentifiersByAttributeName);
      const customAttributeIdentifiers = customAttributeIdentifiersByAttributeName.get(customAttribute) ?? [];
      customAttributeIdentifiersSearchResponse.results?.forEach((customAttributeIdentifier: string) => {
        if (!customAttributeIdentifiers.includes(customAttributeIdentifier)) {
          customAttributeIdentifiers.push(customAttributeIdentifier);
        }
      });
      customAttributeIdentifiersByAttributeName.set(customAttribute, [...customAttributeIdentifiers]);
      return {
        ...state,
        customAttributeIdentifiersByAttributeName,
        loadingCustomAttributeIdentifiers: false,
      };
    },
  ),

  on(IntegrationMetaActions.loadCustomAttributeIdentifiersFailure, (state: IntegrationMetaState) => {
    return {
      ...state,
      loadingCustomAttributeIdentifiers: false,
    };
  }),

  on(
    IntegrationMetaActions.loadCustomAttributesIdentifierAttributesSuccess,
    (
      state: IntegrationMetaState,
      {
        customAttributeIdentifierAttributesByKey,
      }: ReturnType<typeof IntegrationMetaActions.loadCustomAttributesIdentifierAttributesSuccess>,
    ) => {
      const stateCustomAttributeIdentifierAttributesByKey = new Map<string, Column[]>([
        ...state.customAttributeIdentifierAttributesByKey,
        ...customAttributeIdentifierAttributesByKey,
      ]);
      const crossCategoryColumns = {
        ...state.crossCategoryColumns,
      };
      let columns;
      customAttributeIdentifierAttributesByKey.forEach((customAttributeColumns: Column[]) => {
        const categoryId = customAttributeColumns[0].categoryId;
        if (!columns) {
          columns = [...(state.crossCategoryColumns[categoryId] ?? [])];
        }
        customAttributeColumns.forEach((column: Column) => {
          columns.push(
            new Column({
              ...column,
              isNestedAttribute: true,
            }),
          );
        });
        crossCategoryColumns[categoryId] = columns;
      });
      return {
        ...state,
        customAttributeIdentifierAttributesByKey: stateCustomAttributeIdentifierAttributesByKey,
        crossCategoryColumns,
      };
    },
  ),
);

/**
 * IntegrationMeta State Reducer
 * @param {IntegrationMetaState} state
 * @param {Action} action
 * @returns {IntegrationMetaState}
 */
export function integrationMetaState(state: IntegrationMetaState, action: Action): IntegrationMetaState {
  return _reducer(state, action);
}
